* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Khula';
    font-style: normal;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: white;
}

html {
    font-size: 10px;
}

@media only screen and (max-width: 1300px) {
    html {
        font-size: 8.96px;
    }
}

@media only screen and (max-width: 1200px) {
    html {
        font-size: 8.36px;
    }
}

@media only screen and (max-width: 1100px) {
    html {
        font-size: 8.04px;
    }
}

@media only screen and (max-width: 900px) {
    html {
        font-size: 7.68px;
    }
}

@media only screen and (max-width: 800px) {
    html {
        font-size: 7.36px;
    }
}

@media only screen and (max-width: 700px) {
    html {
        font-size: 7.04px;
    }
}

@media only screen and (max-width: 680px) and (min-height: 800px) {
    html {
        font-size: 8px;
    }
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 7.5px;
    }
}

@media only screen and (max-width: 450px) {
    html {
        font-size: 7.1px;
    }
}

@media only screen and (max-width: 350px) {
    html {
        font-size: 6.56px;
    }
}

@media only screen and (max-width: 1000px) and (max-height: 520px) {
    html {
        font-size: 7px;
    }
}

@media only screen and (min-width: 1650px) {
    html {
        font-size: 11.68px;
    }
}

@media only screen and (min-width: 2400px) {
    html {
        font-size: 12.5px;
    }
}