* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Khula";
  font-style: normal;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: white;
}

html {
  font-size: 10px;
}

@media only screen and (max-width: 1300px) {
  html {
    font-size: 8.96px;
  }
}
@media only screen and (max-width: 1200px) {
  html {
    font-size: 8.36px;
  }
}
@media only screen and (max-width: 1100px) {
  html {
    font-size: 8.04px;
  }
}
@media only screen and (max-width: 900px) {
  html {
    font-size: 7.68px;
  }
}
@media only screen and (max-width: 800px) {
  html {
    font-size: 7.36px;
  }
}
@media only screen and (max-width: 700px) {
  html {
    font-size: 7.04px;
  }
}
@media only screen and (max-width: 680px) and (min-height: 800px) {
  html {
    font-size: 8px;
  }
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 7.5px;
  }
}
@media only screen and (max-width: 450px) {
  html {
    font-size: 7.1px;
  }
}
@media only screen and (max-width: 350px) {
  html {
    font-size: 6.56px;
  }
}
@media only screen and (max-width: 1000px) and (max-height: 520px) {
  html {
    font-size: 7px;
  }
}
@media only screen and (min-width: 1650px) {
  html {
    font-size: 11.68px;
  }
}
@media only screen and (min-width: 2400px) {
  html {
    font-size: 12.5px;
  }
}
.app {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #002830;
}
@media screen and (max-width: 850px) {
  .app {
    min-height: 500px;
  }
}
.app__background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  transform: scale(1.1);
}
.app__img {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 15%;
  height: 30vh;
  width: 30vh;
  -webkit-animation: do-levitation 1.2s alternate ease-in-out infinite;
          animation: do-levitation 1.2s alternate ease-in-out infinite;
  z-index: 10;
}
.app .button {
  font-weight: 800;
  font-size: 1.71rem;
  line-height: 2.75rem;
  letter-spacing: 0.04em;
  color: #FCF8F2;
  text-shadow: 0px 0px 4px #01131C;
  background: rgba(0, 181, 194, 0.6);
  border: 3px solid #00B5C2;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
}
.app .button:active {
  background: #00B5C2;
  border: 3px solid #00B5C2;
}
.app__header {
  margin: 5rem 5.8rem 3.7rem;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}
.app__header__name {
  font-weight: 800;
  font-size: 3rem;
  line-height: 4.1rem;
  color: #FCF8F2;
  cursor: pointer;
}
.app__header__name:active {
  color: #00B5C2;
}
@media screen and (max-width: 850px) {
  .app__header__name {
    font-size: 19px;
  }
}
.app__header__button {
  padding: 1rem 2.6rem 0.5rem;
}
@media screen and (max-width: 850px) {
  .app__header__button {
    display: none;
  }
}
.app__box {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app__box__title {
  font-family: "Yatra One";
  font-size: 11rem;
  line-height: 16.25rem;
  color: #FCF8F2;
}
@media screen and (max-width: 850px) {
  .app__box__title {
    font-size: 55px;
  }
}
.app__box__text {
  font-weight: 600;
  font-size: 1.71rem;
  line-height: 3rem;
  margin: -2.8rem 0 3.43rem;
  color: #FCF8F2;
}
@media screen and (max-width: 850px) {
  .app__box__text {
    max-width: 250px;
    text-align: center;
    font-size: 19px;
  }
}
.app__box__text__link {
  font-weight: 800;
  font-size: 1.71rem;
  line-height: 3rem;
  margin: -2.8rem 0 3.43rem;
  color: #FCF8F2;
}
@media screen and (max-width: 850px) {
  .app__box__text__link {
    text-align: center;
    font-size: 19px;
    line-height: 32px;
  }
}
.app__box__button {
  padding: 1.8rem 3rem 1.3rem;
}
@media screen and (max-width: 850px) {
  .app__box__button {
    font-size: 19px;
  }
}
.app__footer {
  z-index: 10;
  margin: auto 5.15rem 3.43rem 5.15rem;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 850px) {
  .app__footer {
    flex-direction: column;
  }
}
.app__footer__text, .app__footer__heavens {
  font-weight: 600;
  font-size: 1.37rem;
  line-height: 2.25rem;
  color: #6E5F70;
}
@media screen and (max-width: 850px) {
  .app__footer__text, .app__footer__heavens {
    font-size: 11px;
  }
}
.app__footer__heavens {
  opacity: 0.9;
}

@-webkit-keyframes do-levitation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 1.5rem);
  }
}

@keyframes do-levitation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 1.5rem);
  }
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0D0E11;
  z-index: 10000;
}
.preloader.animation {
  -webkit-animation: preloader-animation 1.2s ease-in-out;
          animation: preloader-animation 1.2s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.preloader__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  gap: 1.54rem;
  align-items: center;
}
.preloader__box .preloader-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader__box .preloader-image-container .preloader__box__img {
  z-index: 100;
  height: 8.6rem;
  width: 8.6rem;
}
@media screen and (max-width: 850px) {
  .preloader__box .preloader-image-container .preloader__box__img {
    height: 100px;
    width: 100px;
  }
}
.preloader__box .preloader-image-container .preloader__box__img-gray {
  width: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.preloader__box .preloader-image-container .filled {
  transition: all 0.2s;
  width: 100% !important;
}
.preloader__box .number {
  font-weight: 800;
  font-size: 7.2rem;
  line-height: 11.5rem;
  color: #FCF8F2;
}
@media screen and (max-width: 850px) {
  .preloader__box .number {
    font-size: 39px;
    line-height: 63px;
  }
}

@-webkit-keyframes preloader-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes preloader-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}/*# sourceMappingURL=style.css.map */