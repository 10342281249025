.app {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #002830;

    @media screen and (max-width: 850px) {
        min-height: 500px;
    }

    &__background {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        z-index: 1;
        transform: scale(1.1);
    }

    &__img {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 15%;
        height: 30vh;
        width: 30vh;
        animation: do-levitation 1.2s alternate ease-in-out infinite;
        z-index: 10;
    }

    .button {
        font-weight: 800;
        font-size: 1.71rem;
        line-height: 2.75rem;
        letter-spacing: 0.04em;

        color: #FCF8F2;

        text-shadow: 0px 0px 4px #01131C;
        background: rgba(0, 181, 194, 0.6);
        border: 3px solid #00B5C2;
        border-radius: 5px;
        cursor: pointer;

        &:active {
            background: #00B5C2;
            border: 3px solid #00B5C2;
        }
        z-index: 10;
    }

    &__header {
        margin: 5rem 5.8rem 3.7rem;
        display: flex;
        justify-content: space-between;
        z-index: 10;

        &__name {
            font-weight: 800;
            font-size: 3rem;
            line-height: 4.1rem;

            color: #FCF8F2;
            cursor: pointer;

            &:active {
                color: #00B5C2;
            }

            @media screen and (max-width: 850px) {
                font-size: 19px;
            }
        }

        &__button {
            padding: 1rem 2.6rem 0.5rem;

            @media screen and (max-width: 850px) {
                display: none;
            }
        }
    }

    &__box {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
            font-family: 'Yatra One';
            font-size: 11rem;
            line-height: 16.25rem;
            color: #FCF8F2;

            @media screen and (max-width: 850px) {
                font-size: 55px;
            }
        }

        &__text {
            font-weight: 600;
            font-size: 1.71rem;
            line-height: 3rem;
            margin: -2.8rem 0 3.43rem;

            color: #FCF8F2;

            @media screen and (max-width: 850px) {
                max-width: 250px;
                text-align: center;
                font-size: 19px;
            }

            &__link {
                font-weight: 800;
                font-size: 1.71rem;
                line-height: 3rem;
                margin: -2.8rem 0 3.43rem;

                color: #FCF8F2;

                @media screen and (max-width: 850px) {
                    text-align: center;
                    font-size: 19px;
                    line-height: 32px;
                }
            }
        }

        &__button {
            padding: 1.8rem 3rem 1.3rem;

            @media screen and (max-width: 850px) {
                font-size: 19px;
            }
        }
    }

    &__footer {
        z-index: 10;
        margin: auto 5.15rem 3.43rem 5.15rem;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 850px) {
            flex-direction: column;
        }

        &__text, &__heavens {
            font-weight: 600;
            font-size: 1.37rem;
            line-height: 2.25rem;
            color: #6E5F70;

            @media screen and (max-width: 850px) {
                font-size: 11px;
            }
        }

        &__heavens {
            opacity: 0.9;
        }
    }
}

@keyframes do-levitation {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(0, 1.5rem);
    }
}