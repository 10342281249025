.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0D0E11;
    z-index: 10000;

    &.animation {
        animation: preloader-animation 1.2s ease-in-out;
        animation-fill-mode: forwards;
    }

    &__box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-flow: column;
        gap: 1.54rem;
        align-items: center;

        .preloader-image-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .preloader__box__img {
                z-index: 100;
                height: 8.6rem;
                width: 8.6rem;

                @media screen and (max-width: 850px) {
                    height: 100px;
                    width: 100px;
                }
            }

            .preloader__box__img-gray {
                width: 0;
                background-size: cover;
                background-repeat: no-repeat;

            }

            .filled {
                transition: all .2s;
                width: 100% !important;
            }
        }

        .number {
            font-weight: 800;
            font-size: 7.2rem;
            line-height: 11.5rem;
            color: #FCF8F2;

            @media screen and (max-width: 850px) {
                font-size: 39px;
                line-height: 63px;
            }
        }
    }
}

@keyframes preloader-animation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}